




























import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class ShopDetail extends Vue {
  public location = {}; // 地图定位地址
  public user_id = "";
  public address = {}; // 地址信息
  public shop = {}; // 门店信息

  //前往修改地址信息
  // goWrite(){
  //   console.log(this.$api)
  //   // this.$api.href(`/user/set_info?user_id=${this.user_id}&id=${this.address.id}&address=${this.address.address_name}&name=${this.address.realname}&mobile=${this.address.mobile}`);
  //   this.$api.link('/user/set_info',{
  //     user_id:this.user_id,
  //     id:this.address.id,
  //     address:this.address.address_name,
  //     name:this.address.realname,
  //     mobile:this.address.mobile,
  //   })
  // }
  // 获取地址
  getAddress() {
    let _this = this;
    this.$api.request({
      url: "user/users/user-address",
      data: {
        user_id: this.user_id
      },
      success(res) {
        _this.$set(_this.location, "lng", res.data.lng);
        _this.$set(_this.location, "lat", res.data.lat);
        _this.address = res.data;
        _this.shop = res.data.shop;
      }
    });
  } 
  activated(){
    this.location = this.$globalData.location;
    this.user_id = String(this.$route.query.user_id || "");
    this.address = {};
    this.shop = {};
    this.getAddress();
  }
  // init() {
  //   this.location = this.$globalData.location;
  //   this.user_id = String(this.$route.query.user_id || "");
  //   this.address = {};
  //   this.shop = {};
  //   this.getAddress();
  // }
}
